const copyObjectKeyVals = (obj, h) => {
  let res = {};
  for(let k in h) {
    let v = h[k];
    res[k] = obj[v];
  }
  return res;
};

const copyObjectSubKeyVals = (obj, s, h) => {
  let res = {};
  console.log('CALLED HashUtil.copyObjectSubKeyVals s=' + s);
  for(let k in h) {
    let v = h[k];
    let child_val = obj[v];
    console.log('COPYING h key=', k);
    res[k] = child_val[s];
  }
  return res;
};

const HashUtil = {
  copyObjectKeyVals,
  copyObjectSubKeyVals
};

export default HashUtil;
